.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

span {
  color: red;
}

input {
  padding-left: 100px;

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h3 {
  margin-top: 30px;
  margin-bottom: 20px;
  background-color: #94B9D9;
  font-size: 22px;
}

h4 {
  margin-top: 30px;
  font-size: 17px;
}

input .form-control {
  width: 70%;
}

#block {
  border: solid gray 0.5px;
  border-radius: 13px;
  margin: 17px;
  padding: 10px;
}


label {
  text-align: left;
  font-size: 15px;

}

#header {
  margin: 50px;
}

button {
  color: #94B9D9;
}

.signBox {
  border: 1px solid black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}